var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c("dialog-header", {
        attrs: {
          title: "Select a time",
          description:
            "Select a time for the appointment.\nThe meeting will take place in our app.",
        },
      }),
      _c(
        "section-container",
        { attrs: { loading: _vm.loading } },
        [
          _c("h3", { staticClass: "mb-2 text-lg" }, [
            _vm._v(_vm._s(_vm.selectedService.name)),
          ]),
          _c(
            "div",
            { staticClass: "mt-3 mb-3 flex items-center space-x-3" },
            [
              _c("datepicker", {
                attrs: {
                  value: _vm.selectedDate,
                  format: "D d MMMM",
                  "input-class":
                    "flex flex-1 font-black bg-white\n    border rounded cursor-pointer focus:outline-none py-0 h-10 hover:bg-gray-50",
                  "wrapper-class": "top-0 flex-1",
                  "calendar-class":
                    "font-normal rounded shadow uppercase text-sm left-auto",
                  "monday-first": true,
                  "minimum-view": "day",
                  "maximum-view": "month",
                  placeholder: "Select a day",
                  "disabled-dates": _vm.disabledDates,
                },
                on: {
                  input: function ($event) {
                    return _vm.handleSelectedDate($event)
                  },
                },
              }),
              _c("v-select", {
                staticClass: "v-input flex-1 hover:bg-gray-50",
                attrs: {
                  "append-to-body": true,
                  position: "top",
                  clearable: true,
                  searchable: true,
                  label: "name",
                  reduce: function (opt) {
                    return opt.id
                  },
                  options: _vm.availableVets,
                },
                on: { input: _vm.handleSelectedVet },
                model: {
                  value: _vm.selectedVet,
                  callback: function ($$v) {
                    _vm.selectedVet = $$v
                  },
                  expression: "selectedVet",
                },
              }),
            ],
            1
          ),
          _vm.availableSlots.length > 0
            ? _c(
                "list-item",
                _vm._l(_vm.availableSlots, function (slot) {
                  return _c(
                    "button",
                    {
                      key: slot.id,
                      staticClass: "group w-full px-2 py-1 hover:bg-gray-50",
                      on: {
                        click: function (event) {
                          return _vm.handleSelectSlot(slot.id)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center justify-between p-1",
                        },
                        [
                          _c("div", { staticClass: "text-left" }, [
                            _c("p", { staticClass: "mb-1 font-semibold" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.format(new Date(slot.dateTime), "HH:mm")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-gray-500 group-hover:text-gray-700",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(slot.veterinarian.displayName) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("fv-icon", {
                            staticClass: "text-lg text-gray-700",
                            attrs: { icon: "chevron-right" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("h3", { staticClass: "text-center" }, [
                _vm._v("No available slots"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }