<template>
  <div>
    <dialog-header
      title="Select a service"
      :description="`${insuranceCompanyName} includes access to the following FirstVet services`"
    />
    <section-container :loading="loading">
      <list-item v-if="availableServices.length || !loading">
        <button
          v-for="service in servicesOrdered"
          :key="service.id"
          class="w-full px-2 py-4 hover:bg-gray-50"
          :class="{
            'cursor-not-allowed line-through opacity-50': !service.isFree,
          }"
          :disabled="!service.isFree"
          @click="event => handleSelectService(service.id)"
        >
          <div class="flex items-center justify-between p-2">
            <div class="text-left">
              <p class="mb-2 font-semibold">{{ service.name }}</p>
              <p class="text-xs text-gray-500">{{ service.description }}</p>
            </div>
            <div v-show="service.isFree" class="flex items-center gap-x-2">
              <Label label-text="Free" />
              <fv-icon class="text-lg text-gray-700" icon="chevron-right" />
            </div>
          </div>
        </button>
      </list-item>
      <h3 v-else class="text-center text-3xl">No available services</h3>
    </section-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { RemoteBookingApi } from '@/api';
import { errorHandler } from '@/utils/error-handler';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';
import Label from '../../../UI/label/Label.vue';
import ListItem from '../ListItem.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
    ListItem,
    Label,
  },
  data() {
    return {
      availableServices: [],
      loading: false,
    };
  },

  computed: {
    ...mapState('remoteBooking', ['selectedUser', 'selectedAnimal']),
    ...mapState('user', ['user']),
    servicesOrdered() {
      const reference = this.availableServices;

      // Sort on id but free services are prioritized
      return reference.sort((a, b) => {
        if (a.isFree !== b.isFree) {
          return a.isFree ? -1 : 1;
        } else {
          return a.id - b.id;
        }
      });
    },
    insuranceCompanyName() {
      return this.selectedAnimal?.insuranceCompany?.name ?? '';
    },
  },

  async mounted() {
    this.loading = true;

    await this.getServices();
    this.loading = false;
  },

  methods: {
    ...mapMutations('remoteBooking', {
      setSelectedService: 'setSelectedService',
    }),
    handleSelectService(serviceId) {
      const findSelectedService = this.availableServices.find(
        service => service.id === serviceId
      );

      const selectedService = {
        id: serviceId,
        name: findSelectedService.name,
        freeStatus: findSelectedService.freeStatus,
      };

      this.setSelectedService(selectedService);
      this.$emit('next-page');
    },

    isFree(freeStatus) {
      return !!(
        freeStatus.freeForAnimal ||
        freeStatus.freeForInsuranceCompany ||
        freeStatus.freeForUser ||
        freeStatus.hasSubscription
      );
    },

    async getServices() {
      this.loading = true;
      const countryId = this.user.country_id;
      const animalTypeId = this.selectedAnimal.animalType.id;

      const countryServicesParams = { countryId, animalTypeId };
      const serviceParams = {
        countryId,
        animalTypeId,
        locale: 'en',
        withMessage: 1,
        withSlotPrice: 1,
        withNextAvailableSlot: 1,
        withDescriptionForAnimalType: 1,
      };

      try {
        // First get all services for a country and selected animal type
        const { data } = await RemoteBookingApi.fetchServices(
          countryServicesParams
        );
        // Second get detailed service info for each service from previous request
        const response = await Promise.all(
          data.services.map(service =>
            RemoteBookingApi.fetchService(service.id, serviceParams)
          )
        );

        const services = response.map(response => response.data.service);

        const result = [];

        services.forEach(async service => {
          const { data } = await this.getFreeMeetingStatus(service.id);
          result.push({
            ...service,
            freeStatus: data,
            isFree: this.isFree(data),
          });
        });

        this.availableServices = result;
      } catch (error) {
        errorHandler(error);
      }
    },

    async getFreeMeetingStatus(serviceId) {
      const params = {
        animalId: this.selectedAnimal.id,
        serviceId,
      };

      const response = await RemoteBookingApi.fetchFreeMeetingCount(params);
      return response;
    },
  },
};
</script>

<style></style>
